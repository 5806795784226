import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import { Audio } from 'react-loader-spinner';
import { Button } from 'react-bootstrap';
import Layout from "../components/Layout";
import { Hero } from "../components/Hero";
import { About } from "../components/About";
import { Reviews } from "../components/Reviews/Reviews";
import { Steps } from "../components/Steps";
import { Features } from "../components/Features";
import { Bot } from "../components/Bot/Bot";
import { Vacancies } from "../components/Vacancies/Vacancies";
import { Form } from "../components/Form";
import { fetchHomeRequest, fetchCategoriesRequest } from "../services/requests";
import { metaData } from "../constants/metaData";
import EmployerPage from "./EmployerPage";

function Home() {
    const { language } = useSelector((state) => state.global);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);

    const {
        data: apiData,
        mutate: fetchHome,
        isLoading: isHomeDataLoading,
    } = useMutation(fetchHomeRequest);

    const {
        data: categories,
        error: categoriesError,
        isLoading: isCategoriesLoading,
    } = useQuery('categories', fetchCategoriesRequest);

    useEffect(() => {
        fetchHome(language);
    }, [language, fetchHome]);

    const handleEmployerClick = () => {
        setCurrentPage((prevPage) => prevPage + 1);
        navigate('/employer', { state: { currentPage: currentPage + 1 } });
    };

    if (isHomeDataLoading || isCategoriesLoading) {
        return (
            <div className="loader-wrapper">
                <Audio color={"#2E85EC"} />
            </div>
        );
    }

    if (categoriesError) {
        return <div>Error fetching data</div>;
    }

    return (
        <Layout
            title={metaData["home"].title}
            description={metaData["home"].description}
            canonical={`${metaData["home"].canonical}/${language}`}
        >
            <Hero apiData={apiData} language={language} />
            <About apiData={apiData} language={language} />
            <Reviews apiData={apiData} language={language} />
            <Steps apiData={apiData} language={language} />
            <Features apiData={apiData} language={language} />
            <Bot apiData={apiData} language={language} />
            <Vacancies apiData={apiData} language={language} />
            <Form apiData={apiData} language={language} />
            <Button onClick={handleEmployerClick}>Go to Employer Page</Button>
            <ul>
                {categories.map((category) => (
                    <li key={category.id}>{category.name}</li>
                ))}
            </ul>
        </Layout>
    );
}

export default Home;

