import photo1 from '../assets/png/reviews/rev1.png'
import photo2 from '../assets/png/reviews/rev2.png'
import photo3 from '../assets/png/reviews/rev3.png'
import photo4 from '../assets/png/reviews/rev4.png'
import photo5 from '../assets/png/reviews/rev5.png'

const land = [
  {
    ru: {
      nav: {
        link: ['Для бизнеса', '/'],
        button: ['Отправить резюме', 'https://job.buxonline.org/']
      },
      hero: {
        title: 'Сайт для поиска работы удаленно из дому',
        description: 'Дистанционная работа на вашей домашней территории? Наш сайт - ваш идеальный партнер в поиске удаленной работы. Независимо от местоположения, мы поможем вам найти идеальную вакансию, позволяющую работать из дома. Проводите время семьей, избегайте дорогих и утомительных поездок на работу и наслаждайтесь свободой работы удаленно. Присоединяйтесь к нам сегодня и откройте двери к новым возможностям дистанционной карьеры!'
      },
      about: {
        title: 'Что мы предлагаем',
        description: 'Если вы ищете работу и у вас хороший английский, то мы предлагаем вам работу зарубежом. В США, странах Европы, Азии - на ваш вкус',
        subtitle_1: 'Желаемый Опыт',
        text_1: 'Наилучшие шансы есть у специалистов с опытом коммерческой работы от 5-ти лет. Но если у вас меньше лет опыта - ничто вас не ограничивает, только не ожидайте, что мы быстро сможем подобрать вам работу.',
        subtitle_2: 'English - Intermediate',
        text_2: 'У вас должен быть английский не ниже B1 - для того, чтобы вы не только читали и писали, но и понимали что вам говорит зарубежный работодатель на встречах и сами могли ответить.'
      },
      reviews: {
        title: 'Отзывы',
        users: 48,
        success: ['Мы уже трудоустроили почти', 'человек'],
        photos: [photo3, photo4, photo5],
        review: [
          {
            id: 0,
            photo: photo1,
            name: 'Ростислав Прозоровский',
            position: 'Senior PHP Developer',
            text: 'В новой компании я получил позицию Senior PHP Developer. Процесс поиска и выбора вакансий через Buxonline оцениваю хорошо. Это была первая предложенная вакансия скаутом, на нее я и прошёл. Считаю, что Марина хорошо понимает потребности и умеет подобрать вакансии, соответствующие навыкам и ожиданиям специалистов. Качество поддержки, которое я получал от скаута в процессе поиска, оцениваю хорошо. Каких-либо сложностей или непонятностей при сотрудничестве с сервисом Buxonline не было. Единственное было не ясно, как происходит оплата, но этот момент решился и договорились со скаутом о процессе оплаты. Уверен, что этот сервис помог мне повысить шансы успешного трудоустройства за рубежом. Сервис Buxonline уже порекомендовал своим знакомым IT-специалистам, которые ищут работу по прямым контрактам.'
          },
          {
            id: 1,
            photo: photo2,
            name: 'Виктория Збера',
            position: 'HR Manager',
            text: 'Меня зовут Виктория, рада приобщившаяся к Вам. Получила от Владислава приглашение и первое задание написать о себе, так что в нескольких словах опишу себя и свой опыт. Мне давно нравилась работа с людьми, карьеру свою как рекрутер я начала в Польше. Сейчас я занимаюсь дополнительно легализацией работников из Украины подбирая к их потребностям и критерий вакансии в Голландии и Польше. Но с давних пор я также мечтаю попробовать себя в роли рекрутера на более требующие позиции, для того я даже сама попробовала себя в роли тестера, самостоятельно изучив основную информацию, я получила возможность пройти стажировку и поработать в реальном мире ИТ. Мне было интересно и важно говорить с людьми данной сферы "на одном языке" и мне удалось'
          },
        ]
      },
      steps: {
        title: 'Как мы работаем',
        step: [
          {
            title: 'Обработка резюме',
            text: 'Мы проверяем ваше резюме. Находим вам подходящую компанию. Поэтому это может занять какое-то время',
            time: 2,
            label: 'дня'
          },
          {
            title: 'Первое интервью',
            text: 'Если ваше резюме нам подойдет, то мы назначим вам первое интервью, где мы пообщаемся и узнаем друг друга лучше',
            time: 3,
            label: 'дня'
          },
          {
            title: 'Тюнинг',
            text: 'После общения мы пришлем вам анкету, где вы отметите те вещи которые вам однозначно не подходят. Например работа в дейтинге. А также отметите то что вас больше всего привлекает: ненормированный рабочий день, прочее...',
            time: 3,
            label: 'дня'
          },
          {
            title: 'Поиск подходящей компании',
            text: 'Учитывая все ваши пожелания и ваши возможности - процесс поиска подходящей вакансии может занять достаточно большой промежуток времени. Но вы точно будуте довольны результатом!',
            time: 60,
            label: 'дней'
          },
          {
            title: 'Общение с заказчиком',
            text: 'Представитель компании с вами пообщается через зум для того чтобы договориться',
            time: 2,
            label: 'часа'
          },
          {
            title: 'Офер',
            text: 'Мы вам присылаем офер, который вы подписываете, для того чтобы трудоустроиться',
            time: 1,
            label: 'день'
          },
          {
            title: 'Юридическое оформление',
            text: 'Мы высылаем вам договор. В нем указаны условия трудоустройства. Ознакомьтесь, задайте вопросы и подпишите договор для начала работы удаленно.',
            time: 5,
            label: 'дней'
          },
          {
            title: 'Первая зарплата',
            text: 'Через месяц вы получаете свой первый buxonline, путем зачисления первой зарплаты на ваш счет',
            time: 30,
            label: 'дней'
          }
        ]
      },
      feat: [
        'Преимущества',
        'Работайте удаленно в любой точке мира',
        'Мы найдем идеальную работу, соответствующую вашим навыкам',
        'Работайте в удобное для вас время',
        'Помощь и консультации по юридическим вопросам',
        'Довольных сотрудников уже более 250 человек!'
      ],
      bot: [
        'Общение с коллегами',
        'Есть вопросы?',
        'Зайдите в наш чат-бот и задайте ему вопрос',
        'Внимание!',
        'Чат-боту задавайте вопросы на английском языке.'
      ],
      vacancy: {
          title: 'Горячие вакансии',
          salary: 'Оклад',
          content: [
            {
              id: 0,
              title: 'Full Stack разработчик',
              salary: [80,100,'в год'],
              text: 'Гибкий график, проекты с использованием современных технологий.',
              link: ['откликнуться','/']
            },
            {
              id: 1,
              title: 'Data Scientist',
              salary: [90,120,'в год'],
              text: 'Интересные проекты, возможность развиваться в области анализа данных.',
              link: ['откликнуться','/']
            },
            {
              id: 2,
              title: 'DevOps инженер',
              salary: [70,90,'в год'],
              text: 'Автоматизация процессов, работа с облачными платформами, гибкий график.',
              link: ['откликнуться','/']
            },
            {
              id: 3,
              title: 'UI/UX дизайнер',
              salary: [60,80,'в год'],
              text: 'Разработка пользовательских интерфейсов, творческая атмосфера.',
              link: ['откликнуться','/']
            },
            {
              id: 4,
              title: 'Frontend разработчик',
              salary: [70,90,'в год'],
              text: 'Создание современных и отзывчивых интерфейсов, работа в команде.',
              link: ['откликнуться','/']
            },
            {
              id: 5,
              title: 'Разработчик мобильных приложений',
              salary: [80,100,'в год'],
              text: 'Создание инновационных и функциональных мобильных приложений для iOS и Android, использование современных технологий.',
              link: ['откликнуться','/']
            },
          ]
      },
      form: {
        text: 'Присоединяйтесь к нам сегодня и откройте двери к новым возможностям дистанционной карьеры!',
        id: 'rttw562bn89900w2'
      },
      footer: {
        bot: ['Подключиться к телеграм-боту','https://t.me/buxonline'],
        privacy: ['Политика конфиденциальности', '/privacy'],
        cookie: ['Политика использования файлов cookie', '/cookie'],
        rights: 'Все права защищены'
      },
      err: {
        title: 'Страница не найдена',
        subtitle: 'Ошибка 404',
        text: 'Запрашиваемая страница не найдена. Возможно вы ввели неверный адрес или страница была удалена.',
        link: 'Вернуться на главную'
      }
    }
  }
]

export default land
